import './cta.scss';

const CallToAction = ({ booking, phone, language }) => (
  <section className="call-to-action">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">
          {language === 'en' ? 'Looking for professional help?' : '¿Busca ayuda profesional?'}
        </h2>

        <div className="links">
          <a href={booking} rel="noReferrer" target="_blank" className="red-button">
            {language === 'en' ? 'Book A Service' : 'Reserva ahora'}
          </a>

          <span>
            {language === 'en' ? 'or call us' : 'o llámanos'} <a href={`tel:${phone}`}>{phone}</a>
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
