import Slider from 'react-slick';

import './brands.scss';

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const brands_array = [
  'Admiral',
  'Amana',
  'Avanti',
  'Avallon',
  'ASKO',
  'Arietta',
  'Bosch',
  'Cove',
  'Danby',
  'Dacor',
  'Gaggenau',
  'Edgestar',
  'Electrolux',
  'Everstar',
  'Fisher&Paukel',
  'Frigidaire',
  'General Electric',
  'GE Monogramm',
  'Haier',
  'Hotpoint',
  'Jenn Air',
  'Insignia',
  'Kenmore',
  'KitchenAid',
  'LG',
  'Magic shef',
  'Marvel',
  'Miele',
  'Maytag',
  'Roper',
  'Samsung Electronics',
  'Scotsman',
  'Sharp',
  'Speed Queen',
  'Sub-Zero',
  'Summit',
  'Tapan',
  'Thermador',
  'Thor Group',
  'Whirlpool',
  'Wolf',
  'Viking',
  'Vulcan',
  'Z-Line',
  '5 Stars',
];

const Brands = ({ brands, language }) => (
  <section className="brands">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">{language === 'en' ? 'Brands We Repair' : 'Marcas que reparamos'}</h2>
      </div>

      <Slider className="brands__list" {...slider_settings}>
        {brands
          .sort((a, b) => {
            if (a > b) return -1;
            return 1;
          })
          .map((brand, index) => (
            <div key={index}>
              <img src={brand} alt="brand" />
            </div>
          ))}
      </Slider>

      <ul className="brands__array">
        {brands_array.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Brands;
