import Slider from 'react-slick';

import './reviews.scss';

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ reviews, language }) => (
  <section className="reviews">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">{language === 'en' ? 'Reviews' : 'Reseñas'}</h2>
      </div>

      <Slider className="reviews__list" {...slider_settings}>
        {reviews.map((review, index) => (
          <ReviewCard {...{ review, key: index }} />
        ))}
      </Slider>

      <div className="ticker">
        <div className="ticker__text">
          <p>{language === 'en' ? 'Appliance Repair Services' : 'Servicios de reparación de electrodomésticos'}</p>
          <div className="circle" />
          <p>{language === 'en' ? 'Same Day' : 'Mismo día'}</p>
          <div className="circle" />
          <p>{language === 'en' ? 'Appliance Repair Services' : 'Servicios de reparación de electrodomésticos'}</p>
          <div className="circle" />
          <p>{language === 'en' ? 'Same Day' : 'Mismo día'}</p>
        </div>
      </div>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.source) {
    case 'Facebook':
      icon = '/assets/images/f_logo.svg';
      break;
    case 'Yelp':
      icon = '/assets/images/y_logo.svg';
      break;
    default:
      icon = '/assets/images/g_logo.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="icon">
          <img src={icon} alt={review.source} />
        </div>

        <div className="stars">
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
          <img src="/assets/images/star_icon.svg" alt="star" />
        </div>
      </div>

      <div className="reviews__list--card__info">
        <div className="name">{review.name}</div>

        <div className="date">{review.date}</div>
      </div>

      <p>{review.text}</p>
    </div>
  );
};
