import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Seo, Header, Footer } from '../';

import './layout.scss';

const Layout = ({ pageId, phone, booking, location, pageTitle, slug, social_media, language, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pageTitle]);

  return (
    <div id={pageId}>
      <Seo
        data={{
          title: `Sameday Appliance Repair | ${location} | ${pageTitle}`,
        }}
      />
      <Header phone={phone} booking={booking} location={slug} social_media={social_media} language={language} />
      <main>{children}</main>
      <Footer phone={phone} location={slug} email="samedayappliancerepair@gmail.com" />
      <ToastContainer />
      {phone && booking && (
        <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
          <a href={`tel:${phone}`}>{phone}</a>
          <a href={booking} rel="noReferrer" target="_blank">
            {language === 'en' ? 'Book Online' : 'Reserva ahora'}
          </a>
        </div>
      )}
    </div>
  );
};

export default Layout;
