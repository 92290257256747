import { Layout } from '../components';

import { Hero, Offers, WhyWe, About, Services, Reviews, Areas, Brands, CallToAction } from '../sections';

const PageTemplate = ({
  location,
  data,
  phone,
  title,
  areas,
  booking,
  reviews,
  brands,
  social_media,
  content,
  language,
}) => (
  <Layout
    pageId={data.slug}
    phone={phone}
    booking={booking}
    location={location.title}
    slug={location.slug}
    pageTitle={title}
    social_media={social_media}
    language={language}
  >
    <Hero
      title={title}
      image={data.acf.main_image}
      phone={phone}
      booking={booking}
      data={content.hero}
      language={language}
    />
    <Offers data={content.offers} />
    <WhyWe data={content.why_we} />
    <About image={data.acf.about_image} content={language === 'en' ? data.acf.english : data.acf.spanish} />
    <Services services={content.services} location={location} language={language} />
    <Reviews reviews={reviews} language={language} />
    {areas && <Areas areas={areas} language={language} />}
    <Brands brands={brands} language={language} />
    <CallToAction phone={phone} booking={booking} language={language} />
  </Layout>
);

export default PageTemplate;
