import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useFetch from 'react-fetch-hook';

import Home from './pages/Home';
import PageTemplate from './pages/PageTemplate';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';
import PageNotFound from './pages/404';
import ThankYouPage from './pages/ThankYouPage';

import { Spinner } from './components';

const ADMIN_URL = 'https://admin.sameday-repair.com/wp-json/wp/v2';

const App = () => {
  // Static data
  const [lang, setLang] = useState();
  const staticData = useFetch(`${ADMIN_URL}/general_settings?slug=static-data`);

  const params = new URLSearchParams(window.location.search);
  //

  const categories = useFetch(`${ADMIN_URL}/categories?per_page=100`);
  const pages = useFetch(`${ADMIN_URL}/posts?per_page=100`);
  const general_settings = useFetch(`${ADMIN_URL}/general_settings`);

  const [locations, setLocations] = useState();
  const [settings, setSettings] = useState();

  // Define locations
  useEffect(() => {
    if (categories.error || pages.error) return toast.error('Data loading error. Please reload the page!');

    if (!categories.isLoading && !pages.isLoading) {
      const locationsData = categories?.data.map((el) => ({
        phone: el.acf.phone_number,
        booking: el.acf.booking_link,
        areas: el.acf.service_area,
        slug: el.slug,
        title: el.name,
        pages: pages.data
          ?.map((page) => {
            if (page.categories.includes(el.id)) {
              const pageData = {
                title: page.title.rendered,
                acf: page.acf,
                content: page.content.rendered,
                slug: page.slug,
              };

              return pageData;
            }

            return false;
          })
          .filter((page) => page !== undefined),
      }));

      setLocations(locationsData);
    }

    if (!general_settings.isLoading) setSettings(general_settings.data[3].acf);
  }, [categories, pages, general_settings]);

  // Define language
  useEffect(() => {
    const language = params.get('lang');

    if (language && !staticData.isLoading) {
      switch (language) {
        case 'en':
          setLang(staticData.data[0].acf.english);
          break;
        case 'es':
          setLang(staticData.data[0].acf.spanish);
          break;
        default:
          setLang(staticData.data[0].acf.english);
          break;
      }
    }

    if (language === null && !staticData.isLoading) setLang(staticData.data[0].acf.english);
  }, [lang, staticData.isLoading]);

  // if (!locations || !settings || !lang) return <Spinner />;
  if (!locations || !settings || !lang) return <div></div>;

  return (
    <BrowserRouter>
      <Routes>
        {locations.map((location) =>
          location.pages.map((page) => (
            <Fragment>
              <Route
                path={`/${location.slug}/${page.slug}`}
                element={
                  <PageTemplate
                    location={{
                      title: location.title,
                      slug: location.slug,
                    }}
                    data={page}
                    phone={location.phone}
                    title={page.title}
                    areas={location.areas}
                    booking={location.booking}
                    reviews={settings.reviews}
                    brands={settings.brands}
                    social_media={settings.social_media}
                    content={lang}
                    language={params.get('lang') || 'en'}
                  />
                }
              />
              <Route
                path={`/${location.slug}`}
                element={<Navigate replace to={`/${location.slug}/appliance-repair`} />}
              />
            </Fragment>
          ))
        )}

        {/* <Route path="/" element={<Navigate replace to="/tampa" />} /> */}

        <Route path="/" element={<Home cities={categories.data} pages={pages.data} />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/404" element={<PageNotFound />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
