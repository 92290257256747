import './areas.scss';

const Areas = ({ areas, language }) => (
  <section className="areas">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">{language === 'en' ? 'Areas We Serve' : 'Áreas que servimos'}</h2>
      </div>

      <ul>
        {areas
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            return 1;
          })
          .map((el, index) => (
            <li key={index}>{el.name}</li>
          ))}
      </ul>
    </div>
  </section>
);

export default Areas;
