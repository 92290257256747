import './offers.scss';

const Offers = ({ data }) => (
  <section className="offers">
    <div className="container">
      <div className="container__inner">
        <div className="offers__left">
          <p>{data.title}</p>

          <p>
            {data.subtitle.row_1}
            <br />
            {data.subtitle.row_2}
          </p>
        </div>

        <div className="offers__right">
          <div>
            <p>
              <span>${data.coupons.coupon_1.price}</span>
              {data.coupons.coupon_1.text}
            </p>
            <p>
              <span>${data.coupons.coupon_2.price}</span>
              <span>Off</span> {data.coupons.coupon_2.text}
            </p>
          </div>
        </div>

        <div className="offers__mobile">
          <p>
            <span>${data.coupons.coupon_1.price}</span>
            {data.coupons.coupon_1.text}
          </p>
          <p>
            <span>${data.coupons.coupon_2.price} Off</span>
            {data.coupons.coupon_2.text}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Offers;
