import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner, ContentBlock } from '../components';

const ADMIN_URL = 'https://admin.sameday-repair.com/wp-json/wp/v2';

const TermsOfUse = () => {
  const [pageData, setPageData] = useState();

  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/general_settings?slug=settings`);

  useEffect(() => {
    if (data) setPageData(data[0].acf);
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId="terms-of-use" social_media={pageData?.social_media}>
      <Seo />
      <section>
        <ContentBlock title="Terms of Use" content={pageData?.terms_of_use} />
      </section>
    </Layout>
  );
};

export default TermsOfUse;
